export const resource = {
  ru: {
    more: "Подробнее",
    btnTxt: "Свяжиетсь с нами",
    headerTitle: "Все услуги в одном месте",
    additional: {
      title: "Что такое Dpay-кошелек",
      subtitle: "Используйте новые возможности",
      descr:
        "Платежная системе DPAY предлагает универсальное решение для тех, кто хочет попробовать новое – электронный кошелёк. Говоря простым языком, DPAY -кошелёк — это виртуальный счёт, который можно пополнять, оплачивать с него различные товары и услуги и переводить c кошелька на карту и обратно.",
      services: {
        detail: {
          text: "Подробнее",
          icon: "fa-solid fa-arrow-right ml-3",
        },
        autoPay: {
          title: "Услуга «Автоплатеж»",
          subtitle:
            "Для удобства наших пользователей реализованы 2 вида автоплатежей:",
          descr:
            "Уникальная услуга «Автоплатеж» от компании DPAY — это возможность оставаться на связи, либо оплачивать иные услуги партнеров системы в автоматическом режиме.",
          types: ["По расписанию", "По событию"],
          first: {
            title: "Автоплатеж по расписанию",
            text: "Данный автоплатеж позволяет совершать периодические платежи за любые услуги на ежемесячной или еженедельной основе. В рамках данной возможности реализованы следующие варианты:",
            types: [
              "Ежемесячно в последний день",
              "Ежемесячно в указанный день",
              "Еженедельно.",
            ],
            activate: {
              title: "Активация услуги:",
              content:
                "Для создания автоплатежа по расписанию выберите раздел «Оплата». Далее выберите необходимую услугу и другие параметры платежа. При вводе суммы платежа система DPAY предложит создать автоплатеж. Выберите необходимый график платежа и подтвердите автоплатеж.",
            },
          },
          second: {
            title: "Автоплатеж по событию",
            descr:
              "Данный автоплатеж позволяет автоматически пополнять основной баланс Вашего телефонного номера при достижении определенного порога на указанную сумму. Услуга на текущий момент доступна для абонентов UMS, Beeline, Ucell и UzMobile. Сумма пополнения может быть: 5000, 10 000, 15 000, 20 000 и 50 000 сум.",
            activate: {
              title: "Активация услуги:",
              types: [
                "Выберите пункт «Автоплатеж»",
                "Далее, выберите подменю «Создать», укажите номер и установите нужную сумму.",
                "По факту подключения услуги вы получите оповещение в виде SMS-сообщения.",
              ],
              content:
                "В меню «Автоплатеж» вы также можете получить подробную информацию об услуге, настроить список номеров для автопополнения, просмотреть/удалить список номеров для автопополнения.",
            },
          },
        },
        eventPay: {
          title: "Оплата на местах",
          subtitle: "Изменение порядка оплаты на местах",
          descr:
            "Согласно ПП №5252 от 04.10.2021 г. и ПКМ № 943 от 23.11.2019 г. оплаты, проведенные дистанционным образом, должны быть фискализированы и по ним должен быть предоставлен электронный чек с QR-кодом.",
          descr2:
            "С 1 марта 2023 года пользователи могут оплачивать свои покупки через кассовые аппараты, с помощью приложения Dpay.",
          activate: {
            title: "Для этого пользователю необходимо:",
            types: [
              "Сообщить кассиру в точке оплаты, что он желает оплатить покупки через Dpay.",
              "Нажать в приложении Dpay кнопку ///.",
              "Продемонстрировать отображенный на экране телефона QR-код, для сканирования и оплаты.",
              "Готово! После сканирования, на экране отобразится уведомление об успешном платеже.",
            ],
          },
        },
        myInvoice: {
          title: "Услуга «Мои квитанции»",
          descr:
            "Возможность оплаты коммунальных услуг существует в DPAY давно. Но в некоторых ситуациях могут пригодиться фактические квитанции об успешных оплатах.",
          descr2:
            "Специально для таких случаев в системе DPAY появилась возможность скачать электронную версию квитанции или заказать бумажную версию с доставкой по почте в любую точку Узбекистана.",
          typesTitle:
            "Квитанции предоставляются по совершенным оплатам коммунальных услуг и некоторых других сервисов:",
          types: [
            "Газ",
            "Холодная вода",
            "Электроэнергия",
            "Вывоз мусора",
            "Горячая вода и отопление",
            "Штрафы ГУБДД",
          ],
        },
        transfer: {
          title: "Перевод с карты на карту",
          descr:
            "В системе DPAY можно переводить денежные средства непосредственно с одной пластиковой карты на другую, эмитированные банками Узбекистана.",
          descr2:
            "Для удобства пользователей DPAY перевод средств между картами можно осуществить по номеру пластиковой карты получателя, либо по номеру его телефона. При переводе по номеру телефона, получение средств будет возможно после регистрации данного номера в системе DPAY, если таковое не было сделано ранее.",
          types: [
            "Перевод по номеру сотового телефона",
            "Перевод по номеру банковской карты",
            "Перевод между своими банковскими картами",
            "Запрос средств по номеру у другого пользователя",
          ],
        },
      },
    },
    documents: {
      services: [
        { text: "Услуга «Автоплатеж»", link: "#auto" },
        { text: "Оплата на местах", link: "#place" },
        { text: "Услуга «Мои квитанции»", link: "#invoice" },
        { text: "Перевод с карты на карту", link: "#card" },
        { text: "Телеграм-бот", link: "https://t.me/dpaybot" },
      ],
      help: [
        {
          text: "Взаимодействие с оператором",
          link: "https://maipdf.com/est/d12026494684@pdf",
        },
        {
          text: "Инфо о управлении рисками",
          link: "https://maipdf.com/est/d15592500549@pdf",
        },
        {
          text: "Инфо о системе контроля",
          link: "https://maipdf.com/est/d14400500800@pdf",
        },
        {
          text: "Инфо-Безопасность",
          link: "https://maipdf.com/est/d13546493189@pdf",
        },
        {
          text: "Политика конфиденциальности",
          link: "https://maipdf.com/est/d14740500178@pdf",
        },
        {
          text: "Преступная деятельность",
          link: "https://maipdf.com/est/d11005493802@pdf",
        },
        {
          text: "Публичная оферта",
          link: "https://maipdf.com/est/d19015507152@pdf",
        },
      ],
      about: [
        { text: "Что такое Dpay", link: "Services", hash: "#top" },
        { text: "Описание и возможности системы", link: "#more" },
        { text: "Тарифы", link: "#rate" },
        { text: "Вакансии", link: "#jobs" },
      ],
    },
    navLinks: ["Услуги", "Помощь", "О сервисе"],
    mainTitle:
      "Dpay - это платежная система и мобильный банкинг в Узбекистане, быстрая оплата через мобильное приложение с использованием пластиковой карты UZCARD и HUMO.",
    descr: "Описание и возможности системы",
    popularQuests: "Популярные вопросы",

    abilsTitle: "Наши ценности",
    abilsSub:
      "Принципы, которые были нашими путеводителями,оказывали на нас влияние, и сформировали нас в то, кем мы являемся на сегодняшний день.",
    abilsCards: [
      {
        title: "Ответственность",
        desc: "Мы делаем всё правильно, даже когда никто не смотрит. Мы несем ответственность за каждый сделанный шаг. Если это сделано нами, то сделано на совесть.",
      },
      {
        title: "Уважение",
        desc: "Мы относимся к каждой личности с максимально возможным достоинством и уважением. Потому что это то, на чём строятся крепкие взаимоотношения.",
      },
      {
        title: "Инновации",
        desc: "Инновации – это наша сущность. Мы постоянно генерируем новые идеи, которые бросают вызов статусу-кво",
      },
      {
        title: "Обязательства",
        desc: "Мы не просто даём обещания – мы их сдерживаем. Мы выполняем взятые на себя обязательства – перед нашими партнёрами, перед нашими клиентами, и по отношению к друг другу. Наше слово – на вес золота.",
      },
    ],

    service: {
      title: "Тарифы на услуги",
      subtitle: "Обслуживание по системе Dpay",
      subtitle2: "SMS-оповещения",
      descr:
        "Оплата услуг, связанных с получением и отправкой SMS-сообщений в рамках пользования услугой Dpay, списывается оператором сотовой связи непосредственно с абонентского счета клиента.",
      smss: [
        {
          text: "1 SMS-сообщение, отправленное пользователю системой Dpay при успешном проведении операции (оплата/»Автоплатеж»/перевод/переброс средств/запрос баланса)",
          price: ["Бесплатно"],
        },
        {
          text: "1 SMS-сообщение, отправленное пользователю системой Dpay при неуспешном проведении операции (оплата/перевод/переброс средств/запрос баланса)",
          price: ["Бесплатно"],
        },
        {
          text: "1 SMS-сообщение, отправленное пользователю системой Dpay и необходимое для входа в личный кабинет (при выборе пользователем способа авторизации (входа) «Разовым SMS»)",
          price: ["Бесплатно"],
        },
      ],
      services: [
        {
          text: "Подключение к системе Dpay",
          price: ["Бесплатно"],
        },
        {
          text: "Использование системы Dpay",
          price: ["Бесплатно"],
        },
        {
          text: "Оплата за товары и услуги по системе Dpay",
          price: ["0% от суммы платежа*"],
        },
        {
          text: "Перевод средств с пластиковой карты на карту",
          price: ["1% от суммы перевода"],
        },
        {
          text: "История платежей (в месяц)",
          price: ["1000 сум"],
        },
        {
          text: "Открытие Dpay-кошелька",
          price: ["Бесплатно"],
        },
        {
          text: "Пополнение Dpay-кошелька",
          price: ["Бесплатно"],
        },
        {
          text: "Перевод с Dpay-кошелька на Dpay-кошелёк",
          price: ["0%"],
        },
        {
          text: "Перевод с Dpay-кошелька на пластиковые карты",
          price: ["1.5% от суммы перевода"],
        },
        {
          text: "Пополнение Dpay-кошелька через систему PAYNET",
          price: ["2.0%"],
        },
        {
          text: "Пополнение Dpay-кошелька с международных карт VISA и MasterCARD",
          price: ["3.7%"],
        },
        {
          text: "Идентификация физического лица",
          price: ["2250 сум"],
        },
      ],
    },
    jobs: {
      title: "Работа в Dpay",
      descr: "Мы объявляем об открытии ряда вакансий на должности:",
      jobs: [
        {
          text: "UX/UI дизайнера.",
          icon: "fa-brands fa-uikit text-green-400",
        },
        {
          text: "Middle/Senior JAVA Developer.",
          icon: "fa-brands fa-java text-yellow-400",
        },
        {
          text: "PR manager",
          icon: "fa-solid fa-user-tie text-sky-400",
        },
      ],
      inner: {
        first:
          "Нам нужны специалисты среднего уровня или тот, кто готов быстро расти, тот, кто умеет работать руками, понимать дизайн-задачи и следить за достижением результата.",
        second:
          "Если вы тот, кого мы ищем, то скорей присоединяйтесь к нашей дружной команде и добивайтесь успеха вместе с Dpay.",
        email: "Отправить резюме",
      },
    },
    features: [
      {
        thumb: require("../img/payments.svg"),
        title: "Пластиковые карты",
        text: "	В Dpay работают пластиковые карты, к которым подключена услуга SMS-информирования. SMS-информирование подключается бесплатно в любом банке или инфокиоске.",
      },
      {
        thumb: require("../img/security.svg"),
        title: "Денежные переводы",
        text: "Деньги с карты на карту в Dpay переводятся моментально. Чтобы каждый раз не вводить номер карты получателя перевода, в Dpay можно сохранить получателя перевода и повторять перевод по имени получателя.",
      },
      {
        thumb: require("../img/statistics.svg"),
        title: "Платежи",
        text: "В Dpay можно оплатить банковские кредиты, кредиты на бытовую технику, налоги и сборы, услуги бюджетных организаций, свет, газ, интернет и многое другое.",
      },
      {
        thumb: require("../img/cashback.svg"),
        title: "Оплата «на местах»",
        text: "Оплата «на местах» — это альтернативный способ оплаты товаров и услуг с помощью мобильного приложения Dpay для Android и iOS.",
      },
    ],
    slides: [
      {
        thumb: require("../img/01.png"),
        text: "A sed lorem felis, pulvinar pharetra. At tempus, vel sed faucibus amet sit elementum sed erat. Id nunc blandit pharetra facilisis.",
        title: "Step 1.Advanced bydget managment",
      },
      {
        thumb: require("../img/02.png"),
        text: "A sed lorem felis, pulvinar pharetra. At tempus, vel sed faucibus amet sit elementum sed erat. Id nunc blandit pharetra facilisis.",
        title: "Step 2.Latest transaction history",
      },
      {
        thumb: require("../img/03.png"),
        text: "A sed lorem felis, pulvinar pharetra. At tempus, vel sed faucibus amet sit elementum sed erat. Id nunc blandit pharetra facilisis.",
        title: "Step 3.Transfers to people from your contact list",
      },
      {
        thumb: require("../img/04.png"),
        text: "A sed lorem felis, pulvinar pharetra. At tempus, vel sed faucibus amet sit elementum sed erat. Id nunc blandit pharetra facilisis.",
        title: "Step 4.Card-tocard transfers",
      },
      {
        thumb: require("../img/05.png"),
        text: "A sed lorem felis, pulvinar pharetra. At tempus, vel sed faucibus amet sit elementum sed erat. Id nunc blandit pharetra facilisis.",
        title: "Step 4.Card-tocard transfers",
      },
      {
        thumb: require("../img/06.png"),
        text: "A sed lorem felis, pulvinar pharetra. At tempus, vel sed faucibus amet sit elementum sed erat. Id nunc blandit pharetra facilisis.",
        title: "Step 4.Card-tocard transfers",
      },
      {
        thumb: require("../img/07.png"),
        text: "A sed lorem felis, pulvinar pharetra. At tempus, vel sed faucibus amet sit elementum sed erat. Id nunc blandit pharetra facilisis.",
        title: "Step 4.Card-tocard transfers",
      },
      {
        thumb: require("../img/08.png"),
        text: "A sed lorem felis, pulvinar pharetra. At tempus, vel sed faucibus amet sit elementum sed erat. Id nunc blandit pharetra facilisis.",
        title: "Step 4.Card-tocard transfers",
      },
    ],
    logos: [
      require("../img/uzcard.png"),
      require("../img/humocard.png"),
      require("../img/logo-paynet.png"),
      require("../img/ofb.png"),
      require("../img/transtbank.png"),
    ],

    characters: [
      {
        thumb:  require("../img/42-avatar.png"),
      },
      {
        thumb:  require("../img/43-avatar.png"),
      },
      {
        thumb:  require("../img/44-avatar.png"),
      },
      {
        thumb:  require("../img/45-avatar.png"),
      },
      {
        thumb:  require("../img/46-avatar.png"),
      },
      {
        thumb:  require("../img/47-avatar.png"),
      },
    ],
    divisions: [
      {
        title: "Как в Платежах на dpay.uz найти услугу, которую хочу оплатить?",
        text: "Просто введите необходимую услугу или название поставщика услуги в строку поиска в сервисе «Платежи». Напечатать название услуги можно как на латинице, так и на кириллице. Если не нашли необходимую услугу, заполните форму. Мы свяжемся с поставщиком услуги и постараемся её добавить на Dpay.uz.",
      },
      {
        title: "Что можно делать в мобильном приложении Dpay.uz?",
        text: "В мобильном приложении Dpay.uz возможно без комиссии пополнять баланс мобильного, оплачивать счёта за коммуналку, Узбектелеком, детский садик, штрафы ПДД и ещё более 10 000 различных услуг по всему Узбекистану. ",
      },
      {
        title: "Как быстро пройдет мой платёж на Dpay.uz?",
        text: "В пользу большинства поставщиков услуг платёж доходит в течение 1 минуты.",
      },
      {
        title: "Не пришел проверочный код",
        text: "Смс с проверочным кодом приходит в течение 1 минуты. Смс с кодом приходит позже, когда у вашего сотового оператора технические проблемы. Если смс с кодом не пришло в течение 5 минут — обратитесь в службу технической поддержки Dpay по телефону: +998 71 200-89-00.",
      },
      {
        title: "Срок действия карты истёк",
        text: "Чтобы продлить срок действия карты — обратитесь в любой филиал банка, который выпустил карту. В банке предъявите паспорт для подтверждения личности.",
      },
      {
        title: "Карта попала в стоп-лист",
        text: "Чтобы исключить карту из стоп-листа — обратитесь в любой филиал банка, который выпустил карту. В банке предъявите паспорт для подтверждения личности.",
      },
      {
        title: "Превышен лимит ввода PIN-кода карты",
        text: "Если в физическом терминале 3 раза вводится неправильный PIN-код карты — карта блокируется. Чтобы разблокировать карту — обратитесь в любой филиал банка, который выпустил карту. В банке предъявите паспорт для подтверждения личности.",
      },
      {
        title: "Номер карты стёрся",
        text: "Чтобы восстановить номер и другие надписи на карте — обратитесь в любой филиал банка, который выпустил карту. В банке предъявите паспорт для подтверждения личности. Номер и все надписи на карте, в банке восстановят с помощью специального устройства — графического персонизатора.",
      },
      {
        title: "Деньги на карту не поступили",
        text: "СМС с информацией о поступлении денег на карту приходит в течение 1 минуты. СМС придёт позже, когда у вашего сотового оператора технические проблемы.",
      },
    ],
    contacts: [
      "АO “RAQAMLI BIZNES AGREGATOR”",
      "Телефон: +998 (71) 200-95-00",
      "E-mail: info@dgb.uz",
      "Telegram: @dpaybot",
      "Рабочий график: с ПН по ПТ с 9:00 до 18:00",
      "Круглосуточная горячая линия: +998 (71) 200-95-00",
    ],
    aboutSub:
      "Мы – преданная своему делу команда финансовых профессионалов со всего мира со сверх целью создать платежную систему, которая позволила бы вам испытать будущее новых технологий, не теряя человеческого облика.",
    aboutSub2:
      "Найдя золотую середину между технологиями и человечеством, мы создали цифровую систему, ориентированную на человека , которая основывается на потребностях реальных людей.",
    aboutSub3:
      "Мы много занимаемся различными бизнесами, поэтому знаем банковские потребности как физических, так и юридических лиц, даже если они постоянно находятся в движении. Мы знаем что работает, а что нет. Поэтому мы создали платежную систему, которая была бы просто идеальной. Это платёжная система, в которой технологии объединены с человечностью . Это будущее. Уже сегодня. Давайте создавать его вместе.",
  },
  uz: {
    more: "Batafsil",
    btnTxt: "Biz bilan bog'lanish",
    headerTitle: "Barcha xizmatlar bir joyda",
    additional: {
      title: "Dpay Hamyon nima?",
      subtitle:
        "Dpay -Hamyon — bu virtual hisob bo'lib, uni to'ldirish mumkin, undan turli xizmatlarga va xaridlarga to'lasa va pul o'tkazmalarini amalga oshirsa bo'ladi.",
      descr:
        "DPAY to‘lov tizimi yangi narsalarni sinab ko‘rmoqchi bo‘lganlar uchun universal yechim – elektron hamyonni taklif etadi. Oddiy qilib aytganda, DPAY hamyoni virtual hisob bo'lib, uni to'ldirish, undan turli xil tovarlar va xizmatlar uchun to'lash, hamyondan kartaga va aksincha o'tkazish mumkin.",
      services: {
        detail: {
          text: "Batafsil",
          icon: "fa-solid fa-arrow-right ml-3",
        },
        autoPay: {
          title: `"Avtoto'lov" xizmati`,
          subtitle:
            "Foydalanuvchilarimizga qulaylik yaratish maqsadida 2 turdagi avtoto'lovlar amalga oshiriladi:",
          descr:
            "DPAY’dan noyob “Avtoto‘lov” xizmati avtomatik rejimda aloqada bo‘lish yoki tizim hamkorlarining boshqa xizmatlari uchun haq to‘lash imkoniyatidir",
          types: ["Rejalashtirilgan", "Voqea bo'yicha"],
          first: {
            title: "Rejalashtirilgan avtomatik to'lov",
            text: "Ushbu avtomatik to'lov har qanday xizmat uchun oylik yoki haftalik ravishda takroriy to'lovlarni amalga oshirish imkonini beradi. Ushbu parametr quyidagi variantlarni taqdim etadi:",
            types: [
              "Har oy oxirgi kuni",
              "Belgilangan sanada har oy",
              "Haftalik",
            ],
            activate: {
              title: "Xizmatni faollashtirish:",
              content: `Jadvalga muvofiq avtomatik to'lovni yaratish uchun "To'lov" bo'limini tanlang. Keyinchalik, kerakli xizmatni va boshqa to'lov variantlarini tanlang. To'lov miqdorini kiritishda DPAY tizimi avtomatik to'lovni yaratishni taklif qiladi. Kerakli to'lov jadvalini tanlang va avtomatik to'lovni tasdiqlang.`,
            },
          },
          second: {
            title: "Voqea bo'yicha avtomatik to'lov",
            descr:
              "Ushbu avtomatik to'lov belgilangan miqdor uchun ma'lum chegaraga erishilganda telefon raqamingizning asosiy balansini avtomatik ravishda to'ldirish imkonini beradi. Xizmat hozirda UMS, Beeline, Ucell va UzMobile abonentlari uchun amal qiladi. To'ldirish miqdori: 5000, 10 000, 15 000, 20 000 va 50 000 so'm bo'lishi mumkin.",
            activate: {
              title: "Xizmatni faollashtirish:",
              types: [
                `Tanlang paragraf "Avtomatik to'lov»`,
                `Keyinchalik, "Yaratish" pastki menyusini tanlang, raqamni belgilang va kerakli miqdorni belgilang`,
                "Xizmatga ulangandan so'ng siz SMS-xabar shaklida bildirishnoma olasiz",
              ],
              content:
                "Shuningdek, “Avtoto‘lov” menyusida siz xizmat haqida batafsil ma’lumot olishingiz, avtomatik to‘ldirish uchun raqamlar ro‘yxatini sozlashingiz, avtomatik to‘ldirish uchun raqamlar ro‘yxatini ko‘rishingiz/o‘chirishingiz mumkin.",
            },
          },
        },
        eventPay: {
          title: "Joyida to'lash",
          subtitle: "Joylarda to’lov tartibida o’zgarishlar haqida",
          descr:
            "04.10.2021-yilning PQ 5252, hamda Vazirlar Mahkamasining 23.11.2019 yildagi №943 qaroriga binoan, masofadan amalga oshiriluvchi to’lovlar fiskallashtirilishi va ular bo’yicha QR-kodga ega elektron chek taqdim etilishi lozim.",
          descr2:
            "2023-yilning 1-mart kunidan boshlab foydalanuvchilar to’lovlarni kassa apparatlari orqali Dpay ilovasi yordamida amalga oshirish imkoniyatiga ega bo’ladilar.",
          activate: {
            title: "Buning uchun:",
            types: [
              "To’lov nuqtasida sotuvchi-kassirga Dpay orqali to’lamoqchi ekanligingizni ayting",
              "Dpay ilovasida /// tugmasini bosing",
              "Skanerlash va to’lash uchun telefon ekranida paydo bo’lgan QR-kodni kassirga ko’rsating",
              "Tayyor! Skanerlash yakunlangandan so’ng, ekranda muvaffaqiyatli to’lov haqida xabar paydo bo’ladi",
            ],
          },
        },
        myInvoice: {
          title: '"Kvitantsiyalarim" xizmati',
          descr:
            "Kommunal xizmatlarga to'lash imkoni DPAY tizimida beri joriy qilingan. Ba'zi holatlarda ushbu to'lovlar bo'yicha kvitantsiyalar foydali bo’lishi mumkin.",
          descr2:
            "Aynan shunday vaziyatlar uchun DPAY tizimida kvitantsiyaning elektron shaklini yuklab olish yoki butun O'zbekiston bo'ylab istalgan hududga pochta orqali eltib beriladigan qog'oz shakliga buyurtma berish imkoni ishga tushirildi.",
          typesTitle:
            "Kvitantsiyalar kommunal va ba'zi boshqa xizmatlarga oshirilgan to'lovlar bo'yicha taqdim qilinadi:",
          types: [
            "Gaz",
            "Sovuq suv",
            "Elektroenergiya",
            "Chiqindilarini olib chiqish",
            "Issiq suv va isitish",
            "DYHXX jarimalari",
          ],
        },
        transfer: {
          title: "Kartadan kartaga o'tkazish",
          descr:
            "DPAY tizimida Oʻzbekiston banklari tomonidan chiqarilgan bir plastik kartadan boshqasiga toʻgʻridan-toʻgʻri pul oʻtkazishingiz mumkin.",
          descr2:
            "DPAY foydalanuvchilariga qulaylik yaratish maqsadida pul mablag‘larini oluvchining plastik karta raqami yoki uning telefon raqamidan foydalangan holda kartalar o‘rtasida o‘tkazish mumkin. Telefon raqami orqali pul o'tkazishda, agar bu ilgari amalga oshirilmagan bo'lsa, ushbu raqamni DPAY tizimida ro'yxatdan o'tkazgandan so'ng pul mablag'larini olish mumkin bo'ladi.",
          types: [
            "Mobil telefon raqami orqali o'tkazish",
            "Bank kartasi raqami bo'yicha o'tkazma",
            "Bank kartalaringiz o'rtasida o'tkazma",
            "Boshqa foydalanuvchidan raqam bo'yicha pul so'rash",
          ],
        },
      },
    },
    documents: {
      services: [
        { text: `"Avtoto'lov" xizmati`, link: "#auto" },
        { text: "Joyida to'lash", link: "#place" },
        { text: '"Kvitantsiyalarim" xizmati', link: "#invoice" },
        { text: "Kartadan kartaga o'tkazish", link: "#card" },
        { text: "Telegram-bot", link: "https://t.me/dpaybot" },
      ],
      help: [
        {
          text: "Operator bilan suzbat qoidalari",
          link: "https://maipdf.com/est/d12026494684@pdf",
        },
        {
          text: "Tizim ma'lumotlari",
          link: "https://maipdf.com/est/d15592500549@pdf",
        },
        {
          text: "Boshqaruv tizimi ma'lumotlari",
          link: "https://maipdf.com/est/d14400500800@pdf",
        },
        {
          text: "Info-Havsizlik",
          link: "https://maipdf.com/est/d13546493189@pdf",
        },
        {
          text: "Maxfiylik siyosati",
          link: "https://maipdf.com/est/d14740500178@pdf",
        },
        {
          text: "Ommaviy oferta",
          link: "https://maipdf.com/est/d15605506899@pdf",
        },
        {
          text: "Jinoiy faoliyat",
          link: "https://maipdf.com/est/d11005493802@pdf",
        },
      ],
      about: [
        { text: "Dpay nima", link: "Services", hash: "#top" },
        {
          text: "Tizimning tavsifi va imkoniyatlari",
          link: "#more",
        },
        { text: "Tariflar", link: "#rate" },
        { text: "Vakansiyalar", link: "#jobs" },
      ],
    },
    navLinks: ["Xizmatlar", "Yordam", "FAQs"],
    mainTitle:
      "Dpay bu – O‘zbekistonda to‘lov tizimi va mobil banking, UZCARD va HUMO plastik kartalaridan foydalangan holda mobil ilova orqali tezkor to‘lov.",
    descr: "Tizimning tavsifi va imkoniyatlari",
    popularQuests: "Ommabop savollar",
    abilsTitle: "Bizning qadriyatlarimiz",
    abilsSub: `Bizning yo'lboshchimiz bo'lgan tamoyillar bizga ta'sir qildi va bizni bugungi kimligimizni shakllantirdi.`,
    abilsCards: [
      {
        title: "Mas'uliyat",
        desc: "Biz hamma narsani to'g'ri qilamiz, hatto hech kim ko'rmasa ham. Biz qilingan har bir qadam uchun javobgarmiz. Agar bu biz tomonimizdan qilingan bo'lsa, unda bu yaxshi niyat bilan qilingan.",
      },
      {
        title: "Hurmat",
        desc: "Biz har bir insonga maksimal darajada hurmat va hurmat bilan qaraymiz. Chunki kuchli munosabatlar ana shu narsaga asoslanadi.",
      },
      {
        title: "Innovatsiya",
        desc: "Innovatsiyalar bizning mohiyatimizdir. Biz doimiy ravishda status-kvoga qarshi turadigan yangi g'oyalarni ishlab chiqamiz",
      },
      {
        title: "Majburiyatlar",
        desc: "Biz shunchaki va'dalar bermaymiz, ularni bajaramiz. Biz hamkorlarimiz, mijozlarimiz va bir-birimiz oldidagi majburiyatlarimizni bajaramiz. Bizning so'zimiz oltinga arziydi.",
      },
    ],
    service: {
      title: "Xizmatlar uchun tariflar",
      subtitle: "Dpay tizimi bo'yicha xizmat ko'rsatish",
      subtitle2: "SMS-xabarnomalar",
      descr:
        "Dpay xizmatlaridan foydalanish doirasida SMS-xabarlar yuborish va olish bilan bog’liq xizmatlari uchun to’lov mobil operator tomonidan bevosita abonent hisobidan yechib olinadi.",
      smss: [
        {
          text: "Dpay tizimi foydalanuvchisiga (to’lov, “Avtoto’lov”/o’tkazish/balansni so’rash) operatsiyalar muvaffaqiyatli amalga oshirilganda yuborilgan 1 SMS-xabar uchun",
          price: ["Bepul"],
        },
        {
          text: "Dpay tizimi foydalanuvchisiga (to’lov/o’tkazma/balans so’rash) operatsiyalar muvaffaqiyatli amalga oshmay qolganda yuborilgan 1 SMS-xabar uchun	Bepul",
          price: ["Bepul"],
        },
        {
          text: "Dpay tizimi foydalanuvchisiga shaxsiy kabinetga/ilovaga kirish uchun yuborilgan 1 SMS-xabar uchun",
          price: ["Bepul"],
        },
      ],
      services: [
        {
          text: "Dpay tizimiga ulanish",
          price: ["Bepul"],
        },
        {
          text: "Dpay tizimidan foydalanish",
          price: ["Bepul"],
        },
        {
          text: "Dpay tizimi orqali mahsulot va xizmatlarga uchun to’lovlar",
          price: ["To’lov summasidan 0%*"],
        },
        {
          text: "Plastik kartadan boshqa kartaga mablag’ o’tkazish",
          price: ["O’tkazma summasidan 1%"],
        },
        {
          text: "To’lovlar tarixi (oyiga)",
          price: ["1000 so'm"],
        },
        {
          text: "Dpay-Hamyonni ochish",
          price: ["Bepul"],
        },
        {
          text: "Dpay-Hamyonni to'ldirish",
          price: ["Bepul"],
        },
        {
          text: "Dpay-Hamyondan Dpay-Hamyonga mablag' o'tkazish",
          price: ["0%"],
        },
        {
          text: "Dpay-Hamyondan kartaga mablag’ o’tkazish",
          price: ["O’tkazma summasidan 1,5%"],
        },
        {
          text: "PAYNET tizimi orqali Dpay-Hamyonni to'ldirish",
          price: ["2%"],
        },
        {
          text: "VISA va MasterCARD xalqaro kartalari orqali Dpay-Hamyonni to'ldirish",
          price: ["3.7%"],
        },
        {
          text: "Jismoniy shaxs identifikatsiyasi",
          price: ["2250 so'm"],
        },
      ],
    },
    jobs: {
      title: "Dpayda biz bilan ishlang",
      descr:
        "Biz quyidagi bir qancha boʻsh ish oʻrinlari uchun tanlov eʼlon qilamiz:",
      jobs: [
        {
          text: "UX/UI dizayneri.",
          icon: "fa-brands fa-uikit text-green-400",
        },
        {
          text: "O'rta/katta JAVA dasturchisi.",
          icon: "fa-brands fa-java text-yellow-400",
        },
        {
          text: "PR menejeri",
          icon: "fa-solid fa-user-tie text-sky-400",
        },
      ],
      inner: {
        first:
          "Bizga oliy yoki o'rta darajadagi mutaxassislar yoki tez o'sishga tayyor bo'lgan, o'z qo'llari bilan ishlay oladigan, dizayn vazifalarini tushunadigan va natijalarga erishishni nazorat qiladigan kishi kerak.",
        second:
          "Agar siz biz izlayotgan mutaxassis bo'lsangiz, unda bizning do'stona jamoamizga qo'shiling va Dpay bilan muvaffaqiyatga erishing.",
        email: "Rezyumeni yuborish",
      },
    },
    features: [
      {
        thumb: require("../img/payments.svg"),
        title: "Plastik kartalar​",
        text: "Dpay ilovasida SMS-xabarnoma xizmatiga ulangan plastik kartalardan foydalaniladi. SMS-xabarnoma bepul ravishda, istalgan bank yoki infokioskda yoqtirishingiz mumkin.",
      },
      {
        thumb: require("../img/security.svg"),
        title: "Pul o‘tkazmalari​",
        text: "Dpay ilovasida pul mablag’lari kartadan kartaga bir lahzada o‘tadi. O‘tkazma qabul qiluvchining karta raqamini har gal takroran kiritib o‘tirmaslik uchun Dpay da o‘tkazma qabul qiluvchining ism-sharifini saqlab qo‘yish mumkin – keyingi safar qabul qiluvchi ismini tanlashingiz kifoya.",
      },
      {
        thumb: require("../img/statistics.svg"),
        title: "To‘lovlar​",
        text: "Dpay orqali bank kreditlarini, maishiy texnika uchun kreditlarni, soliqlar va yig‘imlarni, byudjet tashkilotlari xizmatlari, elektr, gaz, internet va boshqa ko‘plab xizmatlar haqlarini to‘lash mumkin.",
      },
      {
        thumb:  require("../img/cashback.svg"),
        title: '"Joyida" to‘lash​',
        text: '"Joyida" to‘lash – Android va iOS uchun mo‘ljallangan Dpay mobil ilovasi yordamida tovarlar va xizmatlar haqin to‘lashning muqobil usulidir.',
      },
    ],
    slides: [
      {
        thumb: require("../img/01.png"),
        text: "A sed lorem felis, pulvinar pharetra. At tempus, vel sed faucibus amet sit elementum sed erat. Id nunc blandit pharetra facilisis.",
        title: "Step 1.Advanced bydget managment",
      },
      {
        thumb: require("../img/02.png"),
        text: "A sed lorem felis, pulvinar pharetra. At tempus, vel sed faucibus amet sit elementum sed erat. Id nunc blandit pharetra facilisis.",
        title: "Step 2.Latest transaction history",
      },
      {
        thumb: require("../img/03.png"),
        text: "A sed lorem felis, pulvinar pharetra. At tempus, vel sed faucibus amet sit elementum sed erat. Id nunc blandit pharetra facilisis.",
        title: "Step 3.Transfers to people from your contact list",
      },
      {
        thumb: require("../img/04.png"),
        text: "A sed lorem felis, pulvinar pharetra. At tempus, vel sed faucibus amet sit elementum sed erat. Id nunc blandit pharetra facilisis.",
        title: "Step 4.Card-tocard transfers",
      },
      {
        thumb: require("../img/05.png"),
        text: "A sed lorem felis, pulvinar pharetra. At tempus, vel sed faucibus amet sit elementum sed erat. Id nunc blandit pharetra facilisis.",
        title: "Step 4.Card-tocard transfers",
      },
      {
        thumb: require("../img/06.png"),
        text: "A sed lorem felis, pulvinar pharetra. At tempus, vel sed faucibus amet sit elementum sed erat. Id nunc blandit pharetra facilisis.",
        title: "Step 4.Card-tocard transfers",
      },
      {
        thumb: require("../img/07.png"),
        text: "A sed lorem felis, pulvinar pharetra. At tempus, vel sed faucibus amet sit elementum sed erat. Id nunc blandit pharetra facilisis.",
        title: "Step 4.Card-tocard transfers",
      },
      {
        thumb: require("../img/08.png"),
        text: "A sed lorem felis, pulvinar pharetra. At tempus, vel sed faucibus amet sit elementum sed erat. Id nunc blandit pharetra facilisis.",
        title: "Step 4.Card-tocard transfers",
      },
    ],
    logos: [
      require("../img/uzcard.png"),
      require("../img/humocard.png"),
      require("../img/logo-paynet.png"),
      require("../img/ofb.png"),
      require("../img/transtbank.png"),
    ],

    characters: [
      {
        thumb:  require("../img/42-avatar.png"),
      },
      {
        thumb:  require("../img/43-avatar.png"),
      },
      {
        thumb:  require("../img/44-avatar.png"),
      },
      {
        thumb:  require("../img/45-avatar.png"),
      },
      {
        thumb:  require("../img/46-avatar.png"),
      },
      {
        thumb:  require("../img/47-avatar.png"),
      },
    ],
    divisions: [
      {
        title:
          "Men toʻlamoqchi boʻlgan xizmatni dpay.uz saytidagi Toʻlovlar boʻlimida qanday topsam boʻladi?",
        text: `"To'lovlar" xizmatidagi qidiruv satriga kerakli xizmatni yoki xizmat ko'rsatuvchi provayder nomini kiritish kifoya. Xizmat nomini lotin va kirill alifbolarida chop etishingiz mumkin. Agar kerakli xizmatni topa olmasangiz, shaklni to'ldiring. Biz xizmat ko‘rsatuvchi provayder bilan bog‘lanib, uni Dpay.uz saytiga qo‘shishga harakat qilamiz.`,
      },
      {
        title: "Dpay.uz mobil ilovasida nima qilish mumkin?",
        text: "Dpay.uz mobil ilovasida O‘zbekiston bo‘ylab mobil balansni komissiyasiz to‘ldirish, kommunal to‘lovlar, “O‘zbektelekom”, bolalar bog‘chasi, yo‘l harakati jarimalari va 10 000 dan ortiq turli xizmatlarni to‘lash mumkin.",
      },
      {
        title: "Mening toʻlovim Dpay.uz’ga qanchalik tez tushadi?",
        text: "Aksariyat xizmat ko'rsatuvchi provayderlar to'lovni 1 daqiqa ichida oladilar.",
      },
      {
        title: "Tasdiqlash kodi olinmadi",
        text: "Tasdiqlash kodi bilan SMS 1 daqiqa ichida keladi. Mobil operatoringiz texnik muammolarga duch kelganida kodli SMS keyinroq keladi. Agar kodli SMS 5 daqiqa ichida kelmasa, Dpay texnik yordamiga quyidagi +998 71 200-89-00 telefon raqami orqali murojaat qiling.",
      },
      {
        title: "Karta muddati tugagan",
        text: "Kartaning amal qilish muddatini uzaytirish uchun kartani chiqargan bankning istalgan filialiga murojaat qiling. Shaxsingizni tasdiqlash uchun pasportingizni bankka taqdim eting.",
      },
      {
        title: "Karta to'xtash ro'yxatida",
        text: "To'xtash ro'yxatidan kartani chiqarib tashlash uchun bankning istalgan filialiga murojaat qiling, kartani kim chiqargan. Shaxsingizni tasdiqlash uchun pasportingizni bankka taqdim eting.",
      },
      {
        title: "Karta PIN kodini kiritish chegarasidan oshib ketdi",
        text: "Agar jismoniy terminalda kartaning PIN-kodi noto'g'ri 3 marta kiritilsa, karta bloklanadi. Kartani blokdan chiqarish uchun kartani chiqargan bankning istalgan filialiga murojaat qiling. Shaxsingizni tasdiqlash uchun pasportingizni bankka taqdim eting.",
      },
      {
        title: "Karta raqami oʻchirildi",
        text: "Kartadagi raqam va boshqa yozuvlarni tiklash uchun kartani chiqargan bankning istalgan filialiga murojaat qiling. Shaxsingizni tasdiqlash uchun pasportingizni bankka taqdim eting. Bankdagi kartadagi raqam va barcha yozuvlar maxsus qurilma - grafik personizer yordamida tiklanadi.",
      },
      {
        title: "Pul kartaga tushmagan",
        text: "Kartaga pul tushganligi haqidagi ma'lumot bilan SMS 1 daqiqa ichida keladi. Mobil operatoringiz texnik muammolarga duch kelganda SMS keyinroq keladi.",
      },
    ],
    contacts: [
      "АО “RAQAMLI BIZNES AGREGATOR” MChJ",
      "Telefon: +998 (71) 200-95-00",
      "E-mail: info@dgb.uz",
      "Telegram: @dpaybot",
      "Ish tartibi: dushanbadan jumagacha 9:00 dan 18:00 gacha",
      "24 soatlik ishonch telefoni: +998 (71) 200-95-00",
    ],
    aboutSub: `Biz butun dunyo bo'ylab moliyaviy mutaxassislardan iborat bo'lgan fidoyi jamoamiz va asosiy maqsad insoniylikni yo'qotmasdan yangi texnologiyalar kelajagini boshdan kechirish imkonini beruvchi to'lov tizimini yaratishdir.`,
    aboutSub2: `Texnologiya va insoniyat o'rtasidagi yoqimli nuqtani topib, biz haqiqiy odamlarning ehtiyojlariga asoslangan insonga yo'naltirilgan raqamli tizimni yaratdik.`,
    aboutSub3: `Biz juda ko'p turli xil biznes bilan shug'ullanamiz, shuning uchun biz jismoniy va yuridik shaxslarning bank ehtiyojlarini bilamiz, hatto ular doimo harakatda bo'lsa ham. Biz nima ishlayotganini va nima ishlamasligini bilamiz. Shuning uchun biz mukammal bo'ladigan to'lov tizimini yaratdik. Bu texnologiyani insoniyat bilan birlashtirgan to'lov tizimi. Bu kelajak. Allaqachon bugun. Keling, uni birgalikda yarataylik.`,
  },

  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //

  en: {
    more: "More",
    btnTxt: "Contact us",
    headerTitle: "All services in one place",
    additional: {
      title: "What is Dpay-wallet",
      subtitle: "Use latest features",
      descr:
        "The DPAY payment system offers a universal solution for those who want to try something new - an electronic wallet. In simple terms, a DPAY wallet is a virtual account that can be replenished, paid for various goods and services from it, and transferred from a wallet to a card and vice versa.",
      services: {
        detail: {
          text: "More",
          icon: "fa-solid fa-arrow-right ml-3",
        },
        autoPay: {
          title: "Auto payment service",
          subtitle:
            "For the convenience of our users, 2 types of auto payments are implemented:",
          descr: `The unique "Auto payment" service from DPAY is an opportunity to stay in touch or pay for other services of system partners in automatic mode.`,
          types: ["Scheduled", "By event"],
          first: {
            title: "Scheduled auto payment",
            text: "This auto payment allows you to make recurring payments for any service on a monthly or weekly basis. This option provides the following options:",
            types: [
              "Monthly on the last day",
              "Monthly on the specified date",
              "Weekly",
            ],
            activate: {
              title: "Service activation:",
              content: `To create an auto payment according to the schedule, select the "Payment" section. Next, select the required service and other payment options. When entering the payment amount, the DPAY system will offer to create an auto payment. Select the desired payment schedule and confirm the auto payment.`,
            },
          },
          second: {
            title: "Auto payment by event",
            descr:
              "This auto payment allows you to automatically replenish the main balance of your phone number when a certain threshold is reached for the specified amount. The service is currently available for UMS, Beeline, Ucell and UzMobile subscribers. The replenishment amount can be: 5000, 10,000, 15,000, 20,000 and 50,000 soums.",
            activate: {
              title: "Service activation:",
              types: [
                `Select "Auto Pay"`,
                `Next, select the "Create" submenu, specify the number and set the desired amount.`,
                "Upon connection of the service, you will receive a notification in the form of an SMS message.",
              ],
              content: `In the "Autopayment" menu, you can also get detailed information about the service, set up a list of numbers for auto-replenishment, view/delete the list of numbers for auto-replenishment.`,
            },
          },
        },
        eventPay: {
          title: "Payment on the spot",
          subtitle: "Changing the order of payment on the spot",
          descr:
            "According to GD No. 5252 of 10/04/2021 and RCM No. 943 of 11/23/2019, payments made remotely must be fiscalized and an electronic receipt with a QR code must be provided for them.",
          descr2:
            "From March 1, 2023, users can pay for their purchases through cash registers using the Dpay app.",
          activate: {
            title: "To do this, the user must:",
            types: [
              "Tell the cashier at the point of payment that he wants to pay for purchases through Dpay.",
              "Press the /// button in the Dpay application.",
              "Show the QR code displayed on the phone screen for scanning and payment.",
              "Ready! After scanning, a notification about successful payment will be displayed on the screen.",
            ],
          },
        },
        myInvoice: {
          title: `Service "My receipts"`,
          descr:
            "The possibility of paying utility bills has existed in DPAY for a long time. But in some situations, actual receipts for successful payments can come in handy.",
          descr2:
            "Especially for such cases, the DPAY system has the opportunity to download an electronic version of the receipt or order a paper version with delivery by mail to anywhere in Uzbekistan.",
          typesTitle:
            "Receipts are provided for completed payments for utilities and some other services:",
          types: [
            "Gas",
            "Cold water",
            "Electricity",
            "Garbage removal",
            "Hot water and heating",
            "GUBDD fines",
          ],
        },
        transfer: {
          title: "Transfer from card to card",
          descr:
            "In the DPAY system, you can transfer funds directly from one plastic card to another, issued by banks in Uzbekistan.",
          descr2:
            "For the convenience of DPAY users, funds can be transferred between cards using the recipient's plastic card number or his phone number. When transferring by phone number, receiving funds will be possible after registering this number in the DPAY system, if this has not been done before.",
          types: [
            "Transfer by cell phone number",
            "Transfer by bank card number",
            "Transfer between your bank cards",
            "Requesting funds by number from another user",
          ],
        },
      },
    },
    documents: {
      services: [
        { text: "Auto payment service", link: "#auto" },
        { text: "Payment on the spot", link: "#place" },
        { text: `Service "My receipts"`, link: "#invoice" },
        { text: "Transfer from card to card", link: "#card" },
        { text: "Telegram bot", link: "https://t.me/dpaybot" },
      ],
      help: [
        {
          text: "Interaction with the operator",
          link: "https://maipdf.com/est/d12026494684@pdf",
        },
        {
          text: "Information about risk management",
          link: "https://maipdf.com/est/d15592500549@pdf",
        },
        {
          text: "Information about the control system",
          link: "https://maipdf.com/est/d14400500800@pdf",
        },
        {
          text: "Info-Security",
          link: "https://maipdf.com/est/d13546493189@pdf",
        },
        {
          text: "Privacy Policy",
          link: "https://maipdf.com/est/d14740500178@pdf",
        },
        {
          text: "Criminal activity",
          link: "https://maipdf.com/est/d11005493802@pdf",
        },
        {
          text: "Public offer",
          link: "https://maipdf.com/est/d19015507152@pdf",
        },
      ],
      about: [
        { text: "What is Dpay", link: "Services", hash: "#top" },
        { text: "Description and capabilities of the system", link: "#more" },
        { text: "Rates", link: "#rate" },
        { text: "Jobs", link: "#jobs" },
      ],
    },
    navLinks: ["Services", "Help", "About"],
    mainTitle:
      "Dpay is a payment system and mobile banking in Uzbekistan, fast payment through a mobile application using UZCARD and HUMO plastic cards.",
    descr: "Description and capabilities of the system",
    popularQuests: "Popular questions",

    abilsTitle: "Our values",
    abilsSub:
      "The principles that have been our guides have influenced us and shaped us into who we are today..",
    abilsCards: [
      {
        title: "Responsibility",
        desc: "We do everything right, even when no one is watching. We are responsible for every step taken. If this is done by us, then it is done in good faith.",
      },
      {
        title: "Respect",
        desc: "We treat every individual with the greatest possible dignity and respect. Because that's what strong relationships are built on.",
      },
      {
        title: "Innovations",
        desc: "Innovation is our essence. We are constantly generating new ideas that challenge the status quo",
      },
      {
        title: "Commitments",
        desc: "We don't just make promises, we keep them. We fulfill our obligations – to our partners, to our customers, and to each other. Our word is worth its weight in gold.",
      },
    ],

    service: {
      title: "Service Tariffs",
      subtitle: "Service by Dpay system",
      subtitle2: "SMS alerts",
      descr:
        "Payment for services related to receiving and sending SMS messages within the framework of using the Dpay service is debited by the mobile operator directly from the client's subscriber account.",
      smss: [
        {
          text: `1 SMS message sent to the user by the Dpay system upon successful completion of the operation (payment / "Auto payment" / transfer / transfer of funds / balance request)`,
          price: ["Free"],
        },
        {
          text: "1 SMS message sent to the user by the Dpay system in case of unsuccessful operation (payment/transfer/transfer of funds/balance request)",
          price: ["Free"],
        },
        {
          text: "1 SMS message sent to the user by the Dpay system in case of unsuccessful operation (payment/transfer/transfer of funds/balance request)1 SMS-сообщение, отправленное пользователю системой Dpay и необходимое для входа в личный кабинет (при выборе пользователем способа авторизации (входа) «Разовым SMS»)",
          price: ["Free"],
        },
      ],
      services: [
        {
          text: "Connecting to the Dpay system",
          price: ["Free"],
        },
        {
          text: "Using the Dpay system",
          price: ["Free"],
        },
        {
          text: "Payment for goods and services using the Dpay system",
          price: ["0% of the payment amount"],
        },
        {
          text: "Transferring funds from a plastic card to a card",
          price: ["1% of the transfer amount"],
        },
        {
          text: "Payment history (per month)",
          price: ["1000 sum"],
        },
        {
          text: "Opening a Dpay wallet",
          price: ["Free"],
        },
        {
          text: "Replenishment of Dpay-wallet",
          price: ["Free"],
        },
        {
          text: "Transfer from Dpay wallet to Dpay wallet",
          price: ["0%"],
        },
        {
          text: "Transfer from Dpay wallet to plastic cards",
          price: ["1.5% of the transfer amount"],
        },
        {
          text: "Replenishment of a Dpay-wallet through the PAYNET system",
          price: ["2.0%"],
        },
        {
          text: "Replenishment of Dpay-purse from international cards VISA and MasterCARD",
          price: ["3.7%"],
        },
        {
          text: "Identification of an individual",
          price: ["2250 sum"],
        },
      ],
    },
    jobs: {
      title: "Work at Dpay",
      descr:
        "We are announcing a number of vacancies for the following positions:",
      jobs: [
        {
          text: "UX/UI designer.",
          icon: "fa-brands fa-uikit text-green-400",
        },
        {
          text: "Middle/Senior JAVA Developer.",
          icon: "fa-brands fa-java text-yellow-400",
        },
        {
          text: "PR manager",
          icon: "fa-solid fa-user-tie text-sky-400",
        },
      ],
      inner: {
        first:
          "We need mid-level specialists or someone who is ready to grow quickly, someone who can work with his hands, understand design tasks and monitor the achievement of results.",
        second:
          "If you are the one we are looking for, then join our friendly team and achieve success with Dpay.",
        email: "Send a resume",
      },
    },
    features: [
      {
        thumb:  require("../img/payments.svg"),
        title: "Plastic cards",
        text: "Dpay accepts plastic cards, which are connected to the SMS-informing service. SMS-informing is connected free of charge in any bank or information kiosk.",
      },
      {
        thumb: require("../img/security.svg"),
        title: "Money transfers",
        text: "Money from card to card in Dpay is transferred instantly. In order not to enter the card number of the recipient of the transfer each time, in Dpay you can save the recipient of the transfer and repeat the transfer by the name of the recipient.",
      },
      {
        thumb: require("../img/statistics.svg"),
        title: "Payments",
        text: "In Dpay, you can pay for bank loans, loans for household appliances, taxes and fees, services of budgetary organizations, electricity, gas, Internet and much more.",
      },
      {
        thumb: require("../img/cashback.svg"),
        title: `Payment "on the spot"`,
        text: "Local payment is an alternative way to pay for goods and services using the Dpay mobile app for Android and iOS.",
      },
    ],
    slides: [
      {
        thumb: require("../img/01.png"),
        text: "A sed lorem felis, pulvinar pharetra. At tempus, vel sed faucibus amet sit elementum sed erat. Id nunc blandit pharetra facilisis.",
        title: "Step 1.Advanced bydget managment",
      },
      {
        thumb: require("../img/02.png"),
        text: "A sed lorem felis, pulvinar pharetra. At tempus, vel sed faucibus amet sit elementum sed erat. Id nunc blandit pharetra facilisis.",
        title: "Step 2.Latest transaction history",
      },
      {
        thumb: require("../img/03.png"),
        text: "A sed lorem felis, pulvinar pharetra. At tempus, vel sed faucibus amet sit elementum sed erat. Id nunc blandit pharetra facilisis.",
        title: "Step 3.Transfers to people from your contact list",
      },
      {
        thumb: require("../img/04.png"),
        text: "A sed lorem felis, pulvinar pharetra. At tempus, vel sed faucibus amet sit elementum sed erat. Id nunc blandit pharetra facilisis.",
        title: "Step 4.Card-tocard transfers",
      },
      {
        thumb: require("../img/05.png"),
        text: "A sed lorem felis, pulvinar pharetra. At tempus, vel sed faucibus amet sit elementum sed erat. Id nunc blandit pharetra facilisis.",
        title: "Step 4.Card-tocard transfers",
      },
      {
        thumb: require("../img/06.png"),
        text: "A sed lorem felis, pulvinar pharetra. At tempus, vel sed faucibus amet sit elementum sed erat. Id nunc blandit pharetra facilisis.",
        title: "Step 4.Card-tocard transfers",
      },
      {
        thumb: require("../img/07.png"),
        text: "A sed lorem felis, pulvinar pharetra. At tempus, vel sed faucibus amet sit elementum sed erat. Id nunc blandit pharetra facilisis.",
        title: "Step 4.Card-tocard transfers",
      },
      {
        thumb: require("../img/08.png"),
        text: "A sed lorem felis, pulvinar pharetra. At tempus, vel sed faucibus amet sit elementum sed erat. Id nunc blandit pharetra facilisis.",
        title: "Step 4.Card-tocard transfers",
      },
    ],
    logos: [
      require("../img/uzcard.png"),
      require("../img/humocard.png"),
      require("../img/logo-paynet.png"),
      require("../img/ofb.png"),
      require("../img/transtbank.png"),
    ],

    characters: [
      {
        thumb:  require("../img/42-avatar.png"),
      },
      {
        thumb:  require("../img/43-avatar.png"),
      },
      {
        thumb:  require("../img/44-avatar.png"),
      },
      {
        thumb:  require("../img/45-avatar.png"),
      },
      {
        thumb:  require("../img/46-avatar.png"),
      },
      {
        thumb:  require("../img/47-avatar.png"),
      },
    ],
    divisions: [
      {
        title:
          "How can I find the service I want to pay for in Payments on dpay.uz?",
        text: "Just enter the required service or the name of the service provider in the search bar in the Payments service. You can print the name of the service in both Latin and Cyrillic. If you did not find the service you need, fill out the form. We will contact the service provider and try to add it to Dpay.uz.",
      },
      {
        title: "What can be done in the Dpay.uz mobile application?",
        text: "In the Dpay.uz mobile application, it is possible to replenish the mobile balance without commission, pay utility bills, Uzbektelecom, kindergarten, traffic fines and more than 10,000 different services throughout Uzbekistan.",
      },
      {
        title: "How quickly will my payment go to Dpay.uz?",
        text: "In favor of most service providers, the payment reaches within 1 minute.",
      },
      {
        title: "Verification code not received",
        text: "SMS with a verification code arrives within 1 minute. SMS with the code comes later when your mobile operator has technical problems. If the SMS with the code did not arrive within 5 minutes, contact Dpay technical support by phone: +998 71 200-89-00.",
      },
      {
        title: "Card has expired",
        text: "To extend the validity of the card, contact any branch of the bank that issued the card. Present your passport to the bank to verify your identity.",
      },
      {
        title: "The card is on the stop list",
        text: "To exclude a card from the stop list, contact any branch of the bank that issued the card. Present your passport to the bank to verify your identity.",
      },
      {
        title: "Card PIN entry limit exceeded",
        text: "If an incorrect PIN-code of the card is entered 3 times in the physical terminal, the card is blocked. To unblock the card, contact any branch of the bank that issued the card. Present your passport to the bank to verify your identity.",
      },
      {
        title: "Card number erased",
        text: "To restore the number and other inscriptions on the card, contact any branch of the bank that issued the card. Present your passport to the bank to verify your identity. The number and all the inscriptions on the card, in the bank, will be restored using a special device - a graphic personizer.",
      },
      {
        title: "Money has not been credited to the card",
        text: "SMS with information about the receipt of money on the card comes within 1 minute. SMS will come later when your mobile operator has technical problems.",
      },
    ],
    contacts: [
      "АO “RAQAMLI BIZNES AGREGATOR”",
      "Phone: +998 (71) 200-95-00",
      "E-mail: info@dgb.uz",
      "Telegram: @dpaybot",
      "Work hours: с ПН по ПТ с 9:00 до 18:00",
      "24/7 hotline: +998 (71) 200-95-00",
    ],
    aboutSub:
      "We are a dedicated team of financial professionals from around the world with the ultimate goal of creating a payment system that allows you to experience the future of new technologies without losing your humanity.",
    aboutSub2:
      "Finding the sweet spot between technology and humanity, we have created a human-centered digital system that is based on the needs of real people.",
    aboutSub3:
      "We do a lot of different businesses, so we know the banking needs of both individuals and legal entities, even if they are constantly on the move. We know what works and what doesn't. Therefore, we have created a payment system that would be just perfect. This is a payment system that combines technology with humanity. This is the future. Already today. Let's create it together.",
  },
};
